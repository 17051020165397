import { createStore, applyMiddleware, combineReducers } from 'redux'
import createSagaMiddleware from 'redux-saga'
import survey from "./_reducer/survey";
import logger from 'redux-logger'

const sagaMiddleware = createSagaMiddleware();
const reducers = combineReducers({
  survey
});

const store = createStore(
  reducers,
  applyMiddleware(logger, sagaMiddleware)
);

export default store;
