import React from 'react';
import './App.css';
import { connect } from 'react-redux';

function App(props) {
  props.dispatch({type: 'foo', payload: 'bar'});
  return (
    <div className="App">&nbsp;</div>
  );
}

export default connect()(App);
